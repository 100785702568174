<template>
  <div class="home">
    <div class='homeTop'>
      <div class="homeTopNav">
        <router-link to='/home/homeMain' active-class="active">
          <div class="text">
            工作台
            <div class="bar"></div>
          </div>
        </router-link>
        <router-link to='/home/homeData' active-class="active">
          <div class="text">
            数据
            <div class="bar"></div>
          </div>
        </router-link>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'Home',
  
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/comman.scss';
.home{
  @include container;
  background:#fff;
  border-radius:20px;
  .homeTop{
    padding:30px 0 40px 0;
    width:100%;
    border-bottom:1px solid #eee;
    .homeTopNav{
      width:130px;
      height:100%;
      margin:auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      a{
        text-decoration: none;
        font-size: 16px;
        color:#666;
        &:hover{
          color:$main-color
        }
        .text{
          position: relative;
          .bar{
            display: none;
            @include underLine;
            left:50%;
            transform: translateX(-50%);
          }
        }
      }
      .active{
        color:$main-color;
        .text{
          position: relative;
          .bar{
            display: block;
          }
        }
      }
    }
  }
}
</style>
